import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import Storage from 'app/shared/utils/Storage';

const ShouldNotBeAuth = ({ Success, ...props }) => {
  if (process.browser) {
    const token = Storage.get('authorization');
    if (token) {
      const urlParams = new URLSearchParams(Router.query).toString();
      Router.push(`/editar-perfil?${urlParams}`);
      return null;
    }
    return <Success {...props} />;
  }
  return null;
};

ShouldNotBeAuth.propTypes = {
  Success: PropTypes.func.isRequired,
};

export default ShouldNotBeAuth;
