// External
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

// Internal
import Layout from 'app/features/layout/Layout';
import Form from 'app/shared/components/FormValidate';
import {
  isEmailValid,
  isNullOrEmpty,
  setErrorProps,
} from 'app/shared/utils/validation';
import {
  Card,
  Text,
  H4,
  Logo,
  Flex,
  Col,
  Field,
  Button,
  Message,
  Caption,
  TermsOfService,
} from 'app/components';
import { handleSubmitLogin } from 'app/state/auth/actions';
import useSubmitOnEnter from 'app/shared/hooks/useSubmitOnEnter';

import css from './Login.module.scss';

type FormValues = {
  email: string;
  password: string;
};

const validate = (values: FormValues) => {
  const errors: { email?: string; password?: string } = {};
  const { email, password } = values;

  if (isNullOrEmpty(email)) {
    errors.email = 'E-mail obrigatório';
  } else if (!isEmailValid(email)) {
    errors.email = 'E-mail inválido';
  }

  if (isNullOrEmpty(password)) {
    errors.password = 'Senha obrigatória';
  }

  return errors;
};

type LoginProps = {
  show_error: boolean;
  message: string;
  errors: string[];
};

function Login({ show_error, message, errors }: LoginProps): JSX.Element {
  const dispatch = useDispatch();
  const submitOnEnter = useSubmitOnEnter();
  const router = useRouter();
  const errorMessages = useMemo(
    () =>
      !show_error ? null : (
        <Message kind="error">
          <Text padding="p-bottom" kind="error">
            {message}
          </Text>
          <ul>
            {!!errors.length &&
              errors.map((err) => (
                <li key={err}>
                  <Caption bold kind="error">
                    {`- ${err}`}
                  </Caption>
                </li>
              ))}
          </ul>
        </Message>
      ),
    [show_error, errors],
  );

  return (
    <>
      <Layout title="Login">
        <div style={{ width: '400px' }}>
          <Flex justify="justify-center" margin="m-bottom-xl">
            <div
              tabIndex={0}
              role="button"
              className={css['login-form-logo']}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  router.push('/');
                }
              }}
              onClick={() => router.push('/')}
            >
              <Logo width={300} />
            </div>
          </Flex>
          <Card>
            <Card.Title>
              <H4>Entrar</H4>
            </Card.Title>
            <Card.Body>
              {errorMessages}
              <Form
                validate={validate}
                onSubmitSuccess={(values: FormValues) => {
                  dispatch(handleSubmitLogin(values));
                }}
              >
                {(form: any) => (
                  <>
                    <Flex>
                      <Field
                        type="email"
                        name="email"
                        label="E-mail"
                        value={form.input.email}
                        onChange={form.handleChange}
                        onKeyUp={submitOnEnter(form.handleSubmit)}
                        {...setErrorProps(form.errors.email)}
                      />
                    </Flex>
                    <Flex>
                      <Field
                        type="password"
                        name="password"
                        label="Senha"
                        value={form.input.password}
                        onChange={form.handleChange}
                        onKeyUp={submitOnEnter(form.handleSubmit)}
                        {...setErrorProps(form.errors.password)}
                      />
                    </Flex>
                    <Flex>
                      <Col padding="p-top">
                        <Button
                          onClick={() => router.push('/recuperar-senha')}
                          kind="link"
                          block
                        >
                          Esqueceu sua senha?
                        </Button>
                      </Col>
                    </Flex>
                    <Flex>
                      <Col padding="p-top">
                        <Button
                          block
                          type="submit"
                          kind="success"
                          onClick={form.handleSubmit}
                        >
                          Entrar
                        </Button>
                        <Caption align="center" margin="m-top">
                          {'Ao realizar login, você concorda com os '}
                          <TermsOfService>
                            <Caption kind="blue">termos de uso</Caption>
                          </TermsOfService>
                        </Caption>
                      </Col>
                    </Flex>
                    <Flex>
                      <div className={css['login-form-and-word']}>
                        <Text align="center" kind="subdued">
                          ou
                        </Text>
                      </div>
                    </Flex>
                    <Flex>
                      <Button
                        onClick={() => router.push('/criar-perfil-doutor')}
                        kind="default"
                        block
                      >
                        Criar perfil
                      </Button>
                    </Flex>
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Layout>
    </>
  );
}

export default Login;
