import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';


const Layout = ({ children, title }) => (
  <div style={{ display: 'flex', width: '100%', minHeight: '100vh', justifyContent: 'center', alignItems: 'center' }}>
    <Head>
      <title>{`Perfil médico - ${title}`}</title>
    </Head>
    {children}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
